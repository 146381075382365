import { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface IProps {
  messages: ReactNode;
}

const Root = styled.div`
  overflow: hidden;
  width: 30rem;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  flex: 30 1;
  overflow-y: auto;
  display: flex;
  & > * {
    flex-grow: 1;
  }
`;

const ChatBoxLayout: FC<IProps> = ({ messages }) => {

  return (
    <Root>
      <ContentContainer>
        {messages}
      </ContentContainer>
    </Root>
  );
};

export default ChatBoxLayout;