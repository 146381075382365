import { FC, ReactNode } from 'react';
import styled, { keyframes } from 'styled-components';
import { PlayCircleFilled } from '@ant-design/icons';

interface IProps {
  timestamp: string;
  message: ReactNode;
  $isMine: boolean;
  confidence: number;
  isInprogress: boolean;
  isTtsPlaying: boolean;
}

interface ContainerProps {
  $isMine: boolean;
  confidence?: number;
}

const RootContainer = styled.div<ContainerProps>`
  display: flex;
  justify-content: ${props => props.$isMine ? 'end' : 'start'};
`;

const MessageContainer = styled.div<ContainerProps>`
  padding: 0.5rem;
  display: inline-block;
  background-color: ${props => props.theme.incomingMessageBackground};
  color: ${props => props.theme.incomingMessageFont};
  border-radius: .3rem;
  max-width: 50%;
  min-width: 8rem;
  border-left: ${props => !props.$isMine ? `4px solid ${props.theme.incomingMessageBorder}` : 'none'};
  border-right: ${props => props.$isMine ? `4px solid ${props.theme.outgoingMessageBorder}` : 'none'};

  a {
    color: ${props => props.theme.incomingMessageFont};
  }
`;

const TimestampContainer = styled.div<ContainerProps>`
  font-size: 0.75rem;
  color: ${props =>  props.theme.incomingMessageFont};
`;

const ConfidenceContainer = styled.div<ContainerProps>`
  font-size: 0.75rem;
  color: ${props => props.confidence && props.confidence < 0.7 ? '#d4af37' : 'green'};
  font-weight: 600;
`;

const flash = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
`;

const SpeakerIcon = styled(PlayCircleFilled)`
  animation: ${flash} 1s infinite;
  margin-right: 0.5rem;
`;

const WarningMessage = styled.div`
  font-size: 0.75rem;
  color: red;
  font-style: italic;
  font-weight: 600;
  margin-top: 0.5rem;
`;

const ChatMessageItem: FC<IProps> = ({ timestamp, message, $isMine: isMine, confidence, isInprogress, isTtsPlaying }) => (
  <RootContainer $isMine={isMine}>
    <MessageContainer $isMine={isMine}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <TimestampContainer $isMine={isMine}>{timestamp}</TimestampContainer>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          {isTtsPlaying && <SpeakerIcon />}
          <ConfidenceContainer $isMine={isMine} confidence={confidence}>{Math.round(confidence * 100)}%</ConfidenceContainer>
        </div>
      </div>
      {message}
      {(confidence < 0.5 && !isInprogress) && <WarningMessage>Please repeat your message</WarningMessage>}
    </MessageContainer>
  </RootContainer>
);

export default ChatMessageItem;