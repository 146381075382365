import { ReactNode } from 'react';
import styled from 'styled-components';

const MessagesRoot = styled.div`
  display: flex;
  gap: .5rem;
  padding: .7rem;
  flex-direction: column;
`;

interface IProps {
  children: ReactNode;
}

function ChatMessagesLayout({ children }: IProps): JSX.Element {
  return <MessagesRoot aria-live="polite">
    {children}
  </MessagesRoot>;
}

export default ChatMessagesLayout;