import { FC } from 'react';
import { Anchorme } from 'react-anchorme';
import styled, { keyframes } from 'styled-components';
import ChatMessageItem from './ChatMessageItem';


const TextContainer = styled.div`
  white-space: pre-wrap;
  word-break: normal;
  overflow-wrap: anywhere;
  margin: 0;
  padding-top: .5rem;
  padding-bottom: .5rem;
	font-size: .95rem;
  line-height: 1rem;
`;

const loadingFade = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
`;

const TypingDot = styled.div`
  display: inline-block;
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  background: ${props => props.theme.incomingMessageFont}99; // hex extended with alpha
  margin-left: 0.2rem;
  animation: ${loadingFade} 0.35s infinite;
`;

interface IProps {
  timestamp: string,
  text: string,
  isMine: boolean,
  isStreaming: boolean,
  confidence: number;
  isPlaying: boolean;
}

const TextMessage: FC<IProps> = ({ timestamp, text, isMine, isStreaming, confidence, isPlaying }) => {
  const renderMessage = () => {
    return <Anchorme linkComponent={p => <a {...p} />} target="_blank" rel="noreferrer noopener">{text}</Anchorme>;
  };

  return <ChatMessageItem
    confidence={confidence}
    timestamp={timestamp}
    isInprogress={isStreaming}
    isTtsPlaying={isPlaying}
    message={<TextContainer>{renderMessage()}{isStreaming && <TypingDot />}</TextContainer>}
    $isMine={isMine}
  />;
};

export default TextMessage;
