import { darken } from "polished";
import { FC, useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import ChatMessagesLayout from "../layouts/ChatMessagesLayout";
import { IChannel, ISttMessage } from "../models/signalRModels";
import { useAppSelector } from "../redux/hooks";
import ChatItem from "../presenters/ChatItem";
import { Flex, Form, message, Select, Switch } from "antd";
import Title from "antd/es/typography/Title";
import InputBox from "../presenters/InputBox";
import { IStsHub } from "../signalr/useStsHub";

const RootContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border: 1px solid ${props => props.theme.border};
  border-color: #d3d3d3;
  border-radius: 0.375rem; /* 6px */
  overflow: hidden;
  padding-top: 0.8rem;
  background-color: ${props => props.theme.background};
`;

const ChatContainer = styled.div`
height: 97%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${props => props.theme.background};

  ::-webkit-scrollbar {
    width: 0.6rem;
  }

  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.headerBackground};
    border-radius: 9999px;
    cursor: pointer;

    :hover {
      background-color: ${props => darken(0.05, props.theme.headerBackground)};
    }
  }
`;

interface IProps {
  innerRef: React.RefObject<HTMLDivElement>;
  customer: IChannel | null;
  agent: IChannel | null;
  hub: IStsHub;
}

const EmbeddedMessagesContainer: FC<IProps> = ({ innerRef, customer, hub, agent }) => {

  const [inputValue, setInputValue] = useState('');

  const onTyping = useCallback(
    (text: string) => {
      setInputValue(text);
    },
    [],
  );

  const sendTextMessage = useCallback(
    async () => {
      if (inputValue !== null && agent && inputValue.trim() !== '') {
        hub.sendMessage(agent.channelId, inputValue);
        setInputValue('');
        document.getElementById('bz_message_input')?.focus();
      }
    },
    [agent, hub, inputValue],
  );

  const changeVolume = useCallback(
    (volume: number) => {
      if(agent) {
        hub.changeVolume(agent.channelId, volume);
      }
    },
    [agent, hub],
  );


  const items = useMemo(
    () => {
      if (!agent || !customer) return [];
  
      const agentMessages = agent.transcript
      .filter((msg: ISttMessage) => msg.fromOtherChannelId === null)
      .map((msg: ISttMessage) => ({
        ...msg,
      }));
  
      const customerMessages = customer.transcript
      .filter((msg: ISttMessage) => msg.fromOtherChannelId === null)
      .map((msg: ISttMessage) => ({
        ...msg,
        fromOtherChannelId: customer.channelId,
        originalText: msg.translatedText || msg.originalText,
      }));
  
      return [...agentMessages, ...customerMessages]
        .sort((a: ISttMessage, b: ISttMessage) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())
        .map((msg: ISttMessage) => (<ChatItem key={msg.key} message={msg} />));
    },
    [agent, customer],
  );

  return (
    <RootContainer>
      <ChatContainer>
        <ChatMessagesLayout>
          {items}
        </ChatMessagesLayout>
        <div
          style={{ float: 'left', clear: 'both' }} // hidden div for scrolling
          ref={innerRef}
          role="none"
        />
      </ChatContainer>
      <InputBox inputValue={inputValue} onSubmit={sendTextMessage} onTextChange={onTyping} onVolumeChange={changeVolume}  />
    </RootContainer>
  );
};

export default EmbeddedMessagesContainer;